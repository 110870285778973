import * as moment from 'moment';

//오늘 기준 기본연차
export function calALTodBagicYear(enterDate, workersNumber) {
	const enterD = moment(enterDate);
	const executeTodayDate = moment();
	const emplPeriodY = executeTodayDate.diff(enterD, 'years');
	return parseInt(workersNumber) < 5 ? 0 : emplPeriodY >= 1 ? 15 : 0;
}

//오늘 기준 가산연차
export function calALTodPlusYear(enterDate, workersNumber) {
	const enterD = moment(enterDate);
	const executeTodayDate = moment();
	const emplPeriodY = executeTodayDate.diff(enterD, 'years');
	const sumPlus = emplPeriodY >= 3 ? Math.floor((emplPeriodY - 1) / 2) : 0;
	return parseInt(workersNumber) < 5 ? 0 : sumPlus >= 10 ? 10 : sumPlus;
}

//오늘 기준 월 단위 연차
export function calALTodMonth(enterDate, workersNumber) {
	const enterD = moment(enterDate);
	const executeTodayDate = moment();
	const emplPeriodY = executeTodayDate.diff(enterD, 'years');
	const emplPeriodD = executeTodayDate.diff(enterD, 'month');
	return parseInt(workersNumber) < 5 ? 0 : emplPeriodY < 1 ? emplPeriodD : 0;
}

//올해 기준 월 단위 연차
export function calALTodMonthThisYear(enterDate, workersNumber) {
	const enterD = moment(enterDate);
	const enterYear = moment(enterDate).format('yyyy');
	const lastDate = enterYear + '-12-31';
	const executeTodayDateYearEnd = moment(lastDate);
	const emplPeriodDLastDate = executeTodayDateYearEnd.diff(enterD, 'month');
	return parseInt(workersNumber) < 5 ? 0 : 11 - emplPeriodDLastDate;
}

//오늘 기준 법정 연차 합계
export function calALTodTotal(enterDate, workersNumber) {
	return (
		calALTodBagicYear(enterDate, workersNumber) +
		calALTodPlusYear(enterDate, workersNumber) +
		calALTodMonth(enterDate, workersNumber)
	);
}

export function calALTodRemain(enterDate, workersNumber, usedAL) {
	return (
		calALTodBagicYear(enterDate, workersNumber) +
		calALTodPlusYear(enterDate, workersNumber) +
		calALTodMonth(enterDate, workersNumber) -
		usedAL
	);
}
