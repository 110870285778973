import React from 'react';

export default function WorkerFormTimeComments({ MyTextArea }) {
	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				alignItems: 'center',
				alignContent: 'flex-end',
			}}>
			<div
				className='margin'
				style={{ width: '99%', marginRight: '3px', marginTop: '50px' }}>
				<span>
					● 근로계약서 하단 근로계약 특약사항에 들어가는 특약입니다.(필요한 경우
					사용)
				</span>
			</div>
			<div className='margin' style={{ width: '99.5%', marginRight: '3px' }}>
				<MyTextArea
					multiple
					label='(10) 근로계약서에 들어갈 특약사항 안내(임금계약서는 특약은 3.임금정보에서 작성)'
					placeholder='예) 담당업무, 근무시간, 휴일, 휴가, 근무장소 등의 근로조건 특약 '
					name='workTimeComment'
					rows='2'
				/>
			</div>
		</div>
	);
}
